import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { UserService } from 'src/app/core/http/user/user.service';
import { CoverageService, Lote } from "../../../../core/http/coverage/coverage.service";
import { Router } from "@angular/router";

declare const Natal: any;
declare const BigInt: any;

@Component({
  selector: 'app-dashboard-main',
  templateUrl: './dashboard-main.component.html',
  styleUrls: ['./dashboard-main.component.css']
})
export class DashboardMainComponent implements OnInit {
  modalRef: BsModalRef

  filterForm: FormGroup;
  lotes: any;
  hectareas: number;

  bolsas: bigint;
  bolsasRestantes: bigint


  isLoading = true
  rolUser: string;
  cantidadPlanesSiembraAprobados: number;
  page = 0
  size = 20
  paginaActual: number = 1;
  lastPage = false
  cantidadPlanesSiembraPendientes: number;
  empresas: any;
  cvrDetail: any
  distribuidores: any;
  productores: any;
  lote;
  cantidadPlanesSiembraConSiniestro: number;

  constructor(private filtroBuilder: FormBuilder, private userService: UserService,
    private coverageService: CoverageService, public router: Router,
    private modalService: BsModalService,
  ) {
    let profile = localStorage.getItem('profile');
    if (profile) {
     // this.rolUser = JSON.parse(profile).role;
      this.rolUser = JSON.parse(profile).usuarios[0].role;
    }
    this.createForm();
  }
  ngOnInit(): void {
    this.distribuidores = [];
    this.productores = [];
    this.empresas = [];
    switch (this.rolUser) {
      case 'CORPORATE':
        this.userService.getEmpresas()
          .subscribe(res => {
            this.empresas = res
          }, () => {
            Natal.Notification.error(
              'Error',
              'Se produjo un error al cargar las empresas.',
              false, '', 8000
            );
          })
        break;
      case 'COMPANY':
        this.getDistribuidores();
        break;
      case 'DISTRIBUTOR':
        this.getProductores();
        break;

      default:
    }


    this.loadPage()
  }
  onSearch() {
    this.loadPage()
  }
  getDistribuidores() {
    this.filterForm.get('distribuidor').setValue('');
    this.filterForm.get('productor').setValue('');
    this.distribuidores = [];
    this.productores = [];
    let id = 0;
    if (this.filterForm.value.empresa) {
      id = this.filterForm.value.empresa;
    }
    this.userService.getDistribuidoresEmpresa(id)
      .subscribe(res => {
        this.distribuidores = res
      }, () => {
        Natal.Notification.error(
          'Error',
          'Se produjo un error al cargar los distribuidores.',
          false, '', 8000
        );
      })
  }
  getProductores() {
    this.filterForm.get('productor').setValue('');
    this.productores = [];
    let idDistribuidor = 0;
    if (this.filterForm.value.distribuidor) {
      idDistribuidor = this.filterForm.value.distribuidor;
    }
    this.userService.getProductoresDistribuidor(idDistribuidor)
      .subscribe(res => {
        this.productores = res
      }, () => {
        Natal.Notification.error(
          'Error',
          'Se produjo un error al cargar los productores.',
          false, '', 8000
        );
      })
  }
  loadPage() {
    this.hectareas = 0;
    this.cantidadPlanesSiembraPendientes = 0;
    this.cantidadPlanesSiembraAprobados = 0;
    this.cantidadPlanesSiembraConSiniestro = 0;
    this.bolsas = BigInt("0");
    this.coverageService.getAllDashboard(this.filterForm.value)
      .subscribe(res => {
        this.lotes = res
        if (this.lotes || this.lotes.length) {
          this.lotes.map((lote) => {
            if (lote.estado === 'APROBADO') {
              this.cantidadPlanesSiembraAprobados++;
              this.hectareas += lote.hectareas;
              this.bolsas += BigInt(lote.cantidadInsumo);
            }

            else if (lote.estado === 'PENDIENTE') {
              this.cantidadPlanesSiembraPendientes += 1;
              this.hectareas += lote.hectareas;
              this.bolsas += BigInt(lote.cantidadInsumo);
            }

            if(lote.siniestros.length > 0 ) {
              this.cantidadPlanesSiembraConSiniestro++;
            }
          });

          if (this.filterForm.get('productor').value != '') {
            const productor = this.productores.find(p => p.id == Number(this.filterForm.get('productor').value));
            this.bolsasRestantes = productor.cantidadBolsasMaxima - this.bolsas;
          }

        }
      }, () => {
        Natal.Notification.error(
          'Error',
          'Se produjo un error al obtener los datos.',
          false, '', 8000
        );
      })
  }
  private createForm() {
    this.filterForm = this.filtroBuilder.group({
      empresa: [''],
      distribuidor: [''],
      productor: [''],

    });
    switch (this.rolUser) {
      case 'CORPORATE':
        this.filterForm.get('empresa').setValidators([Validators.required]);
        break;
      case 'COMPANY':
        this.filterForm.get('distribuidor').setValidators([Validators.required]);
        break;
      case 'DISTRIBUTOR':
        this.filterForm.get('productor').setValidators([Validators.required]);
        break;
      default:
    }

  }
  reset() {
    this.filterForm.get('empresa').setValue('');
    this.filterForm.get('distribuidor').setValue('');
    this.filterForm.get('productor').setValue('');
    this.ngOnInit()
  }


  updateState(status: String, lote: Lote) {
    const body = { loteId: lote.id, estado: status };
    this.coverageService.updateState(body, lote.establecimiento.id).subscribe(res => {
      window.location.reload();
      Natal.Notification.success(
        'Exito',
        `El lote se encuentra en estado: ${status}.`,
        false, '', 8000);
    }, err => {
      Natal.Notification.error(
        'Error',
        err.error.data,
        false, '', 8000)
    })
  }
  loadMore() {
    if (!this.isLoading && !this.lastPage) {
      this.page++
      this.loadPage()
    }
  }

  goCoverageEditForm(establecimiento) {
    this.router.navigate(['/shell/coverage/form', establecimiento.id]);
  }


  openAprobarModal(template: TemplateRef<any>, lote) {
    this.lote = lote;
    this.modalRef = this.modalService.show(template);
  }
  openAprobarModal2(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  openRechazarModal(template: TemplateRef<any>, lote) {
    this.lote = lote;
    this.modalRef = this.modalService.show(template);
  }

  getBolsas(){
    const profile = JSON.parse(localStorage.getItem('profile'));
    return profile.selectUsuario?.bolsas?.filter(element => element.campaniaId == profile.campania.id);
  }

  getBolsasUtilizadas(cultivo: string){
    let cantidad = 0;

    if(this.lotes){
      const lotesCultivo = this.lotes.filter(element => element.tipoCultivo === cultivo);

      lotesCultivo.forEach(element => {
        cantidad += element.cantidadInsumo
      });

    }

    return cantidad;
  }
}
