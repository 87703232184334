import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HeaderTitleService {
  private titleSource = new BehaviorSubject<string>('Bienvenido');
  title$ = this.titleSource.asObservable();

  setTitle(title: string): void {
    this.titleSource.next(title);
  }
}
