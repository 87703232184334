import { Component } from '@angular/core';
import { AuthService } from './core/auth/auth.service';
import { LoaderService } from "./core/interceptors/loader.service";
import { delay } from "rxjs/operators";
declare const NF: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  unreadCount = 0;
  loading: boolean = false;
  title = 'campo-protegido';

  public constructor(
    private authService: AuthService, private _loading: LoaderService
  ) {
    authService.handleAuthentication();
  }

  ngOnInit() {
    this.listenToLoading();
    if (this.authService.isAuthenticated()) {
      //this.authService.renewTokens();
    }

    //this.authService.getProfileInfo().then( () => this.messagingService.getPermission())
  }


  /**
   * Listen to the loadingSub property in the LoadingService class. This drives the
   * display of the loading spinner.
   */
  listenToLoading(): void {
    this._loading.loadingSub
      .pipe(delay(0)) // This prevents a ExpressionChangedAfterItHasBeenCheckedError for subsequent requests
      .subscribe((loading) => {
          this.loading = loading;
        if(this.loading) {
          NF.UI.Page.block();
        }
        else {
          NF.UI.Page.unblock();
        }
      });
  }
}
