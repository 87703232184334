import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import * as moment from 'moment';
moment.locale('es');
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable()
export class ExportExcel {

  constructor() { }

  public exportAsExcelFile(json: any[], excelFileName: string): void {
    let data = json.map(data => ({
      'id': data.id,
      'Empresa': data.establecimiento.user.userPadre.userPadre ? data.establecimiento.user.userPadre.userPadre.name : '',
      'Distribuidor': data.establecimiento.user.userPadre ? data.establecimiento.user.userPadre.name : '',
      'Productor': data.establecimiento.user.name,
      'Contacto': data.establecimiento.user.phoneNumber,
      'Localidad': data.establecimiento.localidadCercana,
      'Latitud': data.establecimiento.latitud,
      'Longitud': data.establecimiento.longitud,
      'Establecimiento': data.establecimiento.nombre,
      'Nro. Lote': data.numeroLote,
      'Cultivo': data.tipoCultivo,
      'Fecha Siembra': moment(data.fechaSiembra).utc().format('DD/MM/YYYY'),
      'Fecha de Carga del Plan': moment(data.createdAt).utc().format('DD/MM/YYYY'),
      'Estado': data.estado,
      'Hectáreas': data.hectareas,
      'Insumo': data.insumo,
      'Cantidad': data.cantidadInsumo,
    }))

    const myworksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
    const myworkbook: XLSX.WorkBook = { Sheets: { 'data': myworksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(myworkbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_exported' + EXCEL_EXTENSION);
  }

}
