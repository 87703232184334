import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import * as moment from 'moment';
moment.locale('es');
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable()
export class ExportExcelUser {

  constructor() { }

  public exportAsExcelFile(json: any, excelFileName: string, campaniaSeleccionada: number): void {
    
    let data = json.flatMap(data => {
      return data.bolsas
        .filter(bolsa => bolsa.campania.id === campaniaSeleccionada)
        .map(bolsa => ({
          'ID': data.id,
          'Usuario': data.name,
          'Cuit': data.cuit,
          'Email': data.email,
          'Email 2': data.email2,
          'Email 3': data.email3,
          'Email 4': data.email4,
          'Email 5': data.email5,
          'Telefono': data.phoneNumber,
          'Telefono 2': data.phoneNumber2,
          'Telefono 3': data.phoneNumber3,
          'Telefono 4': data.phoneNumber4,
          'Telefono 5': data.phoneNumber5,
          'Rol': data.role,
          'Estado': data.isActive === true ? 'Activo' : 'Inactivo',
          'Creado por': data.userPadre ? data.userPadre.name : '',
          'Insumo': bolsa.insumo,
          'Tipo de Cultivo': bolsa.tipoCultivo,
          'Campaña': bolsa.campania.anio,
          'Cantidad': bolsa.cantidad,
          'Fecha Creación': moment(data.createdAt).utc().format('DD/MM/YYYY'),
        }));
    });
    const myworksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
    const myworkbook: XLSX.WorkBook = { Sheets: { 'data': myworksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(myworkbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  public formatBolsas(bolsas, campaniaSeleccionada) {
    let bolsasText = '';
    bolsas.forEach(element => {
      if (element.campania.id == campaniaSeleccionada) {
        bolsasText = bolsasText + ' ' + element.tipoCultivo + ' ' + element.campania.anio + ': ' + element.cantidad + ' ' + element.insumo + ', '
      }
    });
    return bolsasText;
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_exported' + EXCEL_EXTENSION);
  }

}
