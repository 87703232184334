<div class="main-panel container-fluid">
  <div class="row actions">
    <div class="col-md-2">
      <button class="btn btn-primary-outline" (click)="showFilters()">
        <div class="icon-filter">
          <i class="fa fa-sliders"></i>
          <i *ngIf="filtroActivo" class="fa fa-warning warning-icon" color="warn"></i>
        </div>
      </button>

    </div>

  </div>
  <form *ngIf="filters" class="form-inline form-header row" novalidate [formGroup]="filterForm" (ngSubmit)="onSearch()">
    <div class="row">
      <div class="col-md-3">
        <div class="form-group">
          <label for="insumo">Insumo</label>
          <ng-multiselect-dropdown id="insumo" [placeholder]="'Seleccione'" formControlName="insumo"
            [settings]="dropdownSettings" [data]="insumos" (onSelect)="onSearch()"
            (onDeSelect)="onDeSelectInsumo($event)" class="filter-insumo">
          </ng-multiselect-dropdown>
        </div>


      </div>

      <div class="col-md-3" *ngIf="rolUser === 'CORPORATE' ">
        <div class="form-group">
          <label for="empresa">Empresa</label>
          <ng-multiselect-dropdown id="empresa" [placeholder]="'Seleccione'" formControlName="empresa"
            [settings]="dropdownSettings" (onSelect)="getDistribuidores()" (onDeSelect)="onDeSelectDistribuidores()"
            [data]="empresas">
          </ng-multiselect-dropdown>
        </div>

      </div>
      <div class="col-md-3" *ngIf="rolUser === 'COMPANY' || rolUser ==='CORPORATE' ">
        <div class="form-group">
          <label for="distribuidor">Distribuidor</label>
          <ng-multiselect-dropdown id="distribuidor" [placeholder]="'Seleccione'" formControlName="distribuidor"
            [settings]="dropdownSettings" (onSelect)="getProductores()" (onDeSelect)="limpiarDistribuidor()"
            [data]="distribuidores">
          </ng-multiselect-dropdown>
        </div>


      </div>
      <div class="col-md-3" *ngIf="rolUser === 'COMPANY' || rolUser === 'DISTRIBUTOR' || rolUser ==='CORPORATE' ">
        <div class="form-group">
          <label for="productor">Productor</label>
          <ng-multiselect-dropdown id="productor" [placeholder]="'Seleccione'" formControlName="productor"
            [settings]="dropdownSettings" [data]="productores" (onSelect)="onSearch()">
          </ng-multiselect-dropdown>
        </div>


      </div>
    </div>
    <div class="row margin-top">
      <div class="col-md-3">
        <button type="button" class="btn btn-primary-outline btn-block" (click)="reset()"
          [disabled]="!filterForm.valid">Limpiar
        </button>
      </div>
    </div>


  </form>
  <div class="row">
    <div class="col-md-6 col-xs-12 margin-top">
      <div class="nf-card">
        <canvas id="chartInsumos" baseChart [datasets]="chartInsumosData" [labels]="chartInsumosLabels"
          [options]="chartInsumosOptions" [legend]="chartInsumosLegend" [chartType]="chartInsumosType" label="Insumos">
        </canvas>
      </div>
    </div>
    <div class="col-md-6 col-xs-12 margin-top">
      <div class="nf-card">
        <canvas baseChart [datasets]="chartHectareasData" [labels]="chartHectareasLabels"
          [options]="chartHectareasOptions" [legend]="chartHectareasLegend" [chartType]="chartHectareasType">
        </canvas>
      </div>
    </div>

    <div class="col-md-6 col-xs-12 margin-top">
      <div class="nf-card">
        <canvas baseChart [datasets]="[{ data: chartTortaData, backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'] }]"
          [labels]="chartTortaLabels" [options]="chartTortaOptions" [legend]="chartTortaLegend"
          [chartType]="chartTortaType">
        </canvas>
      </div>
    </div>

    <div class="col-md-6 col-xs-12 margin-top">
      <div class="nf-card">
        <canvas baseChart [datasets]="chartSiniestrosData" [labels]="chartSiniestrosLabels"
          [options]="chartSiniestrosOptions" [legend]="chartSiniestrosLegend" [chartType]="chartSiniestrosType">
        </canvas>
      </div>
    </div>



    <!-- <div class="col-md-6 col-xs-12">
      <canvas id="chartHectareas" baseChart [datasets]="chartInsumosData" [labels]="chartInsumosLabels"
        [options]="chartOptions" [legend]="chartLegend" [chartType]="chartInsumosType">
      </canvas>
    </div>

    <div class="col-md-6 col-xs-12">
      <canvas id="chartEstados" baseChart [datasets]="chartInsumosData" [labels]="chartInsumosLabels"
        [options]="chartOptions" [legend]="chartLegend" [chartType]="chartInsumosType">
      </canvas>
    </div>

    <div class="col-md-6 col-xs-12">
      <canvas id="chartSiniestros" baseChart [datasets]="chartInsumosData" [labels]="chartInsumosLabels"
        [options]="chartOptions" [legend]="chartLegend" [chartType]="chartInsumosType">
      </canvas>
    </div> -->
  </div>



</div>