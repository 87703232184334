import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient, HttpResponse, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface User {
  commercialTechnician: string,
  userPadreId: number,
  createdAt: string,
  cuit: string,
  cuit2: string,
  cuit3: string,
  cuit4: string,
  cuit5: string,

  email: string,
  email2: string,
  email3: string,
  email4: string,
  email5: string,
  id: number,
  latitude: string,
  longitude: string,
  name: string,
  cantidadBolsasMaxima: string,
  policy: string,
  role: string,
  userPadre: User,
  checked?:boolean,
  tipoCultivo: string,
  eventos: string;
  phoneNumber: string,
  phoneNumber2: string,
  phoneNumber3: string,
  phoneNumber4: string,
  phoneNumber5: string,
  bolsas: Array<CantidadBolsas>
  isActive: boolean,
  emailContacto: string,
  telefonoContacto: string
  terminosEmail: string,
  urlImagenPie: string,
  nameImagenPie: string,
  urlImagenEncabezado: string,
  nameImagenEncabezado: string,
  urlTerminos: string
}
export interface CantidadBolsas {
  id:number,
  cantidad: bigint,
  tipoCultivo: string,
  restantes:bigint,
  campaniaId: number
}
@Injectable({
  providedIn: 'root'
})
export class UserService {

  public resourceUrl = environment.api + '/users';
  public resourcePlanSiembraUrl = environment.api + '/plansiembra';


  constructor(protected http: HttpClient) { }

  getRoles(req?: any): Observable<HttpResponse<any>> {
    return this.http.get<any>(`${this.resourceUrl}/self`, { observe: 'response' });
  }

  getUsers() {
    return this.http.get<any>(`${this.resourceUrl}/getUsers`, { observe: 'response' });
  }
  find(id: number): Observable<any> {
    return this.http.get(this.resourceUrl + '/getById/'+ id);
  }
  getMisProductores() : Observable<Array<User>> {
    return this.http.get<any>(`${this.resourceUrl}/getMisProductores`, { observe: 'response' })
      .pipe(
        map((response) => {
          return response.body;
        })
      );
  }

  getMisDistribuidor() : Observable<Array<User>> {
    return this.http.get<any>(`${this.resourceUrl}/getMisDistribuidor`, { observe: 'response' })
      .pipe(
        map((response) => {
          return response.body;
        })
      );
  }



  getBusinessUnits() {
    return this.http.get<any>(`${environment.api}business-units`, { observe: 'response' });
  }
  getDis(): Observable<Array<any>>{
    return this.http.get<any>(`${this.resourceUrl}/getDis`, { observe: 'response' })
      .pipe(
        map((response) => {
          return response.body;
        })
      );
  }
  getEmpresas(): Observable<Array<any>>{
    return this.http.get<any>(`${this.resourceUrl}/getEmpresas`, { observe: 'response' })
      .pipe(
        map((response) => {
          return response.body;
        })
      );
  }
  getDistribuidoresEmpresa(id): Observable<Array<any>>{
    return this.http.get<any>(`${this.resourceUrl}/getDistribuidoresEmpresa/`+id, { observe: 'response' })
      .pipe(
        map((response) => {
          return response.body;
        })
      );
  }
  getProductoresDistribuidor(id): Observable<Array<any>>{

    return this.http.get<any>(`${this.resourceUrl}/getProductoresDistribuidor/`+id, { observe: 'response' })
      .pipe(
        map((response) => {
          return response.body;
        })
      );
  }

  reenviarInvitacion(id: any): Observable<any> {
    return this.http.get(`${this.resourceUrl}/reenvioMail/${id}`)
  }
 

  setUser(idUserSeleccionado: string): Observable<any>{
    return this.http.put(`${this.resourceUrl}/selectUser/${idUserSeleccionado}`,{ observe: 'response' })
  }

  setCampania(idCampania: string): Observable<any>{
    return this.http.put(`${this.resourceUrl}/selectCampania/${idCampania}`,{ observe: 'response' })
  }

  reenviarMultiple(ids:number[]): Observable<any>{
    return this.http.put<any>(`${this.resourceUrl}/reenvioMailMultiple`, {ids:ids}, { observe: 'response' });
  }
  
  aceptarTerminos(): Observable<any>{
    return this.http.put(`${this.resourceUrl}/aceptarTerminos`,{ observe: 'response' })
  }
}
