import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as auth0 from 'auth0-js';
import { EventManager } from '../../utils/event-manager.service';
import { environment } from '../../../environments/environment';
import { UserService } from '../http/user/user.service';
import { StorageService } from '../http/storage/storage.service';

declare const NF: any;


@Injectable({
  providedIn: 'root'
})
export class AuthService {


  public resourceUrl = environment.api + 'users';
  auth0 = new auth0.WebAuth({
    clientID: environment.auth0.clientId,
    domain: environment.auth0.domain,
    responseType: 'token id_token',
    redirectUri: window.location.origin + '/callback',
    scope: 'email'
  });


  constructor(
    public router: Router,
    private eventManager: EventManager,
    private userService: UserService,
    private storageService: StorageService,
  ) {
  }

  get accessToken(): string {
    return localStorage.getItem('access_token');
  }

  get idToken(): string {
    return localStorage.getItem('id_token');
  }

  public login(): void {
    this.auth0.authorize({ type: 'both' });
    //this.auth0.authorize();

  }

  public handleAuthentication(): void {
    this.auth0.parseHash((err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        this.localLogin(authResult);
        this.eventManager.broadcast({ name: 'login' });
        this.userService.getRoles().subscribe(
          result => {
            if (result.body) {
              const profile = JSON.parse(localStorage.getItem('profile'));
              localStorage.setItem('profile', JSON.stringify({ ...profile, usuarios: result.body, role: result.body[0].role, terminosAceptados: result.body[0].terminosAceptados }));
              this.router.navigate(['/shell']);
            }
          },
          error => {
            this.logout();

            NF.Notification.show({
              type: NF.Notification.Type.ERROR,
              title: "Error",
              content: 'No se encontro el usuario',
              position: NF.Notification.Positions.TOP_RIGHT,
              clickClose: true,
              autoHideDelay: 8000,
            });
          }
        );
      } else if (err) {
        this.logout();
        NF.Notification.show({
          type: NF.Notification.Type.ERROR,
          title: "Error",
          content: 'Error inesperado',
          position: NF.Notification.Positions.TOP_RIGHT,
          clickClose: true,
          autoHideDelay: 8000,
        });
      }
    });
  }

  private localLogin(authResult): void {
    // Set the time that the access token will expire at
    const expiresAt = (authResult.expiresIn * 100000) + Date.now();
    localStorage.setItem('id_token', authResult.idToken);
    localStorage.setItem('access_token', authResult.accessToken);
    localStorage.setItem('expires_at', `${expiresAt}`);
    localStorage.setItem('profile', JSON.stringify(authResult.idTokenPayload));
  }

  public renewTokens(): void {
    this.auth0.checkSession({}, (err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        this.localLogin(authResult);
      }
      //  else if (err) {
      //    alert(`Could not get a new token (${err.error}: ${err['error_description']}).`);
      //    this.logout();
      //  }
    });
  }

  public logout(): void {
    // Remove tokens and expiry time
    this.eventManager.broadcast({ name: 'logout' });
    localStorage.removeItem('id_token');
    localStorage.removeItem('access_token');
    localStorage.removeItem('expires_at');
    localStorage.removeItem('profile');
    this.storageService.removeItemStorage('filtro');
    this.auth0.logout({
      returnTo: window.location.origin + '/home'
    });
  }

  public isAuthenticated(): boolean {
    // Check whether the current time is past the
    // access token's expiry time
    const expiresAt = parseInt(localStorage.getItem('expires_at'));
    return localStorage.getItem('access_token') && Date.now() < expiresAt;
  }

  public getProfileInfo() {
    return new Promise((resolve, reject) => {
      const profile = localStorage.getItem('profile');
      if (profile) {
        const parsedProfile = JSON.parse(profile);
        resolve(parsedProfile);
      } else {
        reject();
        this.logout();
      }
    });
  }

}
