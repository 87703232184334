import { ChangeDetectorRef, Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from 'src/app/core/http/user/user.service';
import { CoverageService, Lote } from '../../../../core/http/coverage/coverage.service';
import { Router } from '@angular/router';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { StorageService } from '../../../../core/http/storage/storage.service';
import { HeaderTitleService } from 'src/app/shared/services/header.service';
import { forkJoin } from 'rxjs';
import { MediaMatcher } from '@angular/cdk/layout';

declare const NF: any;

@Component({
  selector: 'app-dashboard-main',
  templateUrl: './dashboard-main.component.html',
  styleUrls: ['./dashboard-main.component.css']
})
export class DashboardMainComponent implements OnInit {
  filters = false;

  filterForm: FormGroup;
  isLoading = true;
  rolUser: string;

  empresas: any;
  distribuidores: any;
  productores: any;
  lote;
  filtro: any;
  filtroActivo = false;
  dropdownSettings: IDropdownSettings = {};
  itemsEmpresa: Array<any> = [];
  itemsDistribuidor: Array<any> = [];
  itemsProductor: Array<any> = [];
  itemsInsumo: Array<any> = [];

  campaniaSeleccionada;
  insumos = [
    {
      name: 'Bolsa Semilla de 40 kg',
      id: 'Bolsa Semilla de 40 kg'
    },
    {
      name: 'Bolsa Semilla',
      id: 'Bolsa Semilla'
    },
    {
      name: 'Fertilizante',
      id: 'Fertilizante'
    },
    {
      name: 'Herbicida',
      id: 'Herbicida'
    },
    {
      name: 'Capital a Financiar',
      id: 'Capital a Financiar'
    }
  ];



  totalObjetivo: 0;
  totalUtilizado: 0;
  hectareasPorCampania: Array<any>

  //INSUMOS
  chartInsumosOptions = {
    title: {
      display: true,
      text: 'Insumos',
      fontSize: 24
    },
    responsive: true,
    maintainAspectRatio: false,
    tooltips: {
      callbacks: {
        label: function (tooltipItem: any, data: any) {
          const dataset = data.datasets[tooltipItem.datasetIndex];
          const value = parseFloat(dataset.data[tooltipItem.index]);
          return value.toLocaleString('es'); // Asegura que incluso números grandes se muestren correctamente
        }
      }
    },
    scales: {
      xAxes: [
        {
          ticks: {
            beginAtZero: true, // Configura el inicio en 0
            callback: function (value: number) {
              return value.toLocaleString('es'); // Formato con separador de miles
            }
          }
        }
      ],
      yAxes: [
        {
          ticks: {
            autoSkip: false // Asegura que no se omitan etiquetas
          }
        }
      ]
    }
  };
  chartInsumosType = 'horizontalBar';
  chartInsumosLegend = true;
  chartInsumosLabels = ['Objetivo', 'Cargados en Planes'];
  chartInsumosData = [
    { data: [0, 0], label: '', backgroundColor: '#0a45955c', hoverBackgroundColor: '#0a4595', borderColor: '#0a4595' },
  ];


  //HECTAREAS
  chartHectareasOptions = {
    title: {
      display: true,
      text: 'Hectareas Planes Activos',
      fontSize: 24
    },
    responsive: true,
    maintainAspectRatio: false,
    tooltips: {
      callbacks: {
        label: function (tooltipItem: any, data: any) {
          const dataset = data.datasets[tooltipItem.datasetIndex];
          const value = parseFloat(dataset.data[tooltipItem.index]);
          return value.toLocaleString('es'); // Asegura que incluso números grandes se muestren correctamente
        }
      }
    },
    scales: {
      xAxes: [
        {
          ticks: {
            beginAtZero: true, // Configura el inicio en 0
            callback: function (value: number) {
              return value.toLocaleString('es'); // Formato con separador de miles
            }
          }
        }
      ],
      yAxes: [
        {
          ticks: {
            autoSkip: false // Asegura que no se omitan etiquetas
          }
        }
      ]
    }
  };

  chartHectareasLabels: string[] = []; // Etiquetas para las campañas
  chartHectareasData: any[] = [];      // Datos para las hectáreas
  chartHectareasType = 'horizontalBar';
  chartHectareasLegend = true;

  //ESTADOS PLANES

  chartTortaOptions = {
    responsive: true,
    maintainAspectRatio: false,
    title: {
      display: true,
      text: 'Estado Plan de Siembra',
      fontSize: 24
    },
  };

  chartTortaLabels: string[] = []; // Etiquetas para los estados
  chartTortaData: number[] = [];   // Datos para las cantidades

  chartTortaType = 'pie';
  chartTortaLegend = true;

  //SINIESTROS

  chartSiniestrosOptions = {
    title: {
      display: true,
      text: 'Siniestros',
      fontSize: 24
    },
    responsive: true,
    maintainAspectRatio: false,
    indexAxis: 'y',
    scales: {
      xAxes: [
        {
          ticks: {
            beginAtZero: true,       // La escala comienza en 0
            stepSize: 1,            // Incrementos de 1
            callback: function (value: any) {
              return Number.isInteger(value) ? value : ''; // Muestra solo valores enteros
            }
          }
        }
      ],
      yAxes: [
        {
          ticks: {
            autoSkip: false // Asegura que no se omitan etiquetas en el eje Y
          }
        }
      ]
    }

  };

  chartSiniestrosLabels: string[] = []; // Etiquetas para los estados
  chartSiniestrosData: any[] = [];      // Datos agrupados por eventos

  chartSiniestrosType = 'horizontalBar';
  chartSiniestrosLegend = true;
  chartSiniestrosColors = ['#66BB6A', '#FFA726', '#29B6F6', '#EF5350']; // Colores para cada evento

  isMobile: boolean = false;
  mobileQuery: MediaQueryList;
  private mobileQueryListener: () => void;


  constructor(private filtroBuilder: FormBuilder, private userService: UserService,
    private coverageService: CoverageService, public router: Router,
    private storageService: StorageService,
    private headerTitleService: HeaderTitleService,
    private changeDetectorRef: ChangeDetectorRef,

    private mediaMatcher: MediaMatcher,
  ) {
    this.mobileQuery = mediaMatcher.matchMedia('(max-width: 800px)');
    this.mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addEventListener('change', this.mobileQueryListener);
    this.isMobile = this.mobileQuery.matches;
    this.chartInsumosOptions.maintainAspectRatio = !this.isMobile;
    this.chartTortaOptions.maintainAspectRatio = !this.isMobile;
    this.chartHectareasOptions.maintainAspectRatio = !this.isMobile;
    this.chartSiniestrosOptions.maintainAspectRatio = !this.isMobile;

    let profile = JSON.parse(localStorage.getItem('profile'));
    if (profile) {
      this.rolUser = profile.role;
      this.campaniaSeleccionada = profile.campania.id;
      if (profile.selectUsuario.role != 'CORPORATE') {
        const insumosDisponibles = this.getInsumosDisponibles(profile.selectUsuario);
        this.insumos = insumosDisponibles;
      }

    }
    this.createForm();
  }

  ngOnInit(): void {
    this.headerTitleService.setTitle('Tablero de Control');

    this.distribuidores = [];
    this.productores = [];
    this.empresas = [];
    this.dropdownSettings = {
      singleSelection: true,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Seleccionar todo',
      unSelectAllText: 'Deseleccionar todo',
      searchPlaceholderText: 'Buscar',
      enableCheckAll: false,
      itemsShowLimit: 3,
      allowSearchFilter: true,
      closeDropDownOnSelection: true,
      noDataAvailablePlaceholderText: 'No hay valores disponibles',

    };
    this.getDataFilters();

    this.filtro = this.storageService.getStorageObject('filtro', 'tablero');

    if (this.filtro && (this.filtro.distribuidor || this.filtro.empresa || this.filtro.productor || this.filtro.insumo)) {
      this.filtroActivo = true;
    }

  }

  async getDataFilters() {
    switch (this.rolUser) {
      case 'CORPORATE':
        this.empresas = await this.userService.getEmpresas().toPromise();
        break;
      case 'COMPANY':
        this.distribuidores = await this.userService.getDistribuidoresEmpresa(0).toPromise();
        break;
      case 'DISTRIBUTOR':
        this.productores = await this.userService.getProductoresDistribuidor(0).toPromise();
        break;

      default:
    }
    if (this.filtro && this.filtro.empresa !== "" && this.empresas && this.empresas.length) {
      this.empresas.map((item) => {
        if (item.id === this.filtro.empresa) {
          this.itemsEmpresa.push({
            id: item.id,
            name: item.name
          });
          return;
        }
      });
      this.filterForm.get('empresa').setValue(this.itemsEmpresa, {
        onlySelf: true
      });
      this.filterForm.get('empresa').updateValueAndValidity();

      this.distribuidores = await this.userService.getDistribuidoresEmpresa(this.filtro.empresa).toPromise();
    }

    if (this.filtro && this.filtro.distribuidor !== "" && this.distribuidores && this.distribuidores.length) {
      this.distribuidores.map((item) => {
        if (item.id === this.filtro.distribuidor) {
          this.itemsDistribuidor.push({
            id: item.id,
            name: item.name
          });
          return;
        }
      });
      this.filterForm.get('distribuidor').setValue(this.itemsDistribuidor, {
        onlySelf: true
      });
      this.filterForm.get('distribuidor').updateValueAndValidity();

      this.productores = await this.userService.getProductoresDistribuidor(this.filtro.distribuidor).toPromise();
    }

    if (this.filtro && this.filtro.productor !== "" && this.productores && this.productores.length) {
      this.productores.map((item) => {
        if (item.id === this.filtro.productor) {
          this.itemsProductor.push({
            id: item.id,
            name: item.name
          });
          return;
        }
      });
      this.filterForm.get('productor').setValue(this.itemsProductor, {
        onlySelf: true
      });
      this.filterForm.get('productor').updateValueAndValidity();

    }
    if (this.filtro && this.filtro.insumo !== "") {
      this.insumos.map((item) => {
        if (item.id === this.filtro.insumo) {
          this.itemsInsumo.push({
            id: item.id,
            name: item.name
          });
          return;
        }
      });
      this.filterForm.get('insumo').setValue(this.itemsInsumo, {
        onlySelf: true
      });
      this.filterForm.get('insumo').updateValueAndValidity();

    }
    else {
      this.itemsInsumo.push({
        id: this.insumos[0].id,
        name: this.insumos[0].name
      });
      this.filterForm.get('insumo').setValue(this.itemsInsumo, {
        onlySelf: true
      });
    }

    this.onSearch();
  }


  onSearch() {
    const filtro = {
      empresa: this.filterForm.value.empresa && this.filterForm.value.empresa.length ? this.filterForm.value.empresa[0].id : '',
      distribuidor: this.filterForm.value.distribuidor && this.filterForm.value.distribuidor.length ? this.filterForm.value.distribuidor[0].id : '',
      productor: this.filterForm.value.productor && this.filterForm.value.productor.length ? this.filterForm.value.productor[0].id : '',
      insumo: this.filterForm.value.insumo && this.filterForm.value.insumo.length ? this.filterForm.value.insumo[0].id : this.insumos[0].id,
      registrosPorPagina: 10
    };
    this.filtro = filtro;

    this.storageService.setStorageObject('filtro', 'tablero', filtro);
    this.loadPage();
  }

  loadPage() {
    const params = {
      empresa: this.filtro.empresa,
      distribuidor: this.filtro.distribuidor,
      productor: this.filtro.productor,
      insumo: this.filtro.insumo,
    };

    forkJoin({
      totalObjetivo: this.coverageService.getReporteInsumosObjetivo(params),
      totalUtilizado: this.coverageService.getReporteInsumosUtilizados(params),
      reporteHectareas: this.coverageService.getReporteHectareasPorCampania(params),
      estadoPlanes: this.coverageService.getReporteEstadosPlanesSiembra(params),
      siniestros: this.coverageService.getReporteSiniestrosPorEstadoYEvento(params)

    }).subscribe({
      next: (results) => {
        this.totalObjetivo = results.totalObjetivo.total;
        this.totalUtilizado = results.totalUtilizado.total;

        this.chartInsumosData = [
          {
            data: [this.totalObjetivo, this.totalUtilizado],
            label: this.filtro.insumo,
            backgroundColor: '#0a45955c',
            hoverBackgroundColor: '#0a4595',
            borderColor: '#0a4595'
          },
        ];

        const labelsHectareas = results.reporteHectareas.map(row => row.campania); // Extrae las campañas
        const dataHectareas = results.reporteHectareas.map(row => row.totalHectareas); // Extrae las hectáreas

        // Actualiza los datos del gráfico
        this.chartHectareasLabels = labelsHectareas;
        this.chartHectareasData = [
          {
            data: dataHectareas,
            label: 'Hectareas',
            backgroundColor: '#66BB6A', // Color único para las barras
            borderColor: '#388E3C',
            hoverBackgroundColor: '#388E3C',
            borderWidth: 1,
          },
        ];

        const labelsEstados = results.estadoPlanes.map(row => row.estado);          // Extrae los estados
        const dataEstados = results.estadoPlanes.map(row => row.cantidadLotes);     // Extrae las cantidades

        // Actualiza los datos del gráfico
        this.chartTortaLabels = labelsEstados;
        this.chartTortaData = dataEstados;



        const estados: string[] = [...new Set((results.siniestros as any[]).map(row => this.getEstadoSiniestro(row.estado) as string))];
        const eventos = [...new Set(results.siniestros.map(row => row.evento))]; // Obtiene eventos únicos

        // Crea los datasets para cada evento
        const datasets = eventos.map((evento, index) => ({
          label: evento,
          data: estados.map(estado => {
            const siniestro = results.siniestros.find(row => this.getEstadoSiniestro(row.estado) === estado && row.evento === evento);
            return siniestro ? siniestro.cantidadSiniestros : 0;
          }),
          backgroundColor: this.chartSiniestrosColors[index % this.chartSiniestrosColors.length]
        }));

        // Actualiza las etiquetas y datos del gráfico
        this.chartSiniestrosLabels = estados;
        this.chartSiniestrosData = datasets;
      },
      error: () => {
        NF.Notification.show({
          type: NF.Notification.Type.ERROR,
          title: "Error",
          content: 'Se produjo un error al obtener los datos.',
          position: NF.Notification.Positions.TOP_RIGHT,
          clickClose: true,
          autoHideDelay: 8000,
        });
      },
    });
  }


  onDeSelectDistribuidores() {
    this.onSearch();

    this.reset();
    this.storageService.deleteStorageObject('filtro', 'tablero');
  }


  getDistribuidores() {
    this.onSearch();

    this.filterForm.get('distribuidor').setValue('');
    this.filterForm.get('productor').setValue('');
    this.distribuidores = [];
    this.productores = [];
    let id = 0;
    if (this.filterForm.value.empresa && this.filterForm.value.empresa.length) {
      id = this.filterForm.value.empresa[0].id;
    }
    this.userService.getDistribuidoresEmpresa(id)
      .subscribe(res => {
        this.distribuidores = res;
      }, () => {

        NF.Notification.show({
          type: NF.Notification.Type.ERROR,
          title: "Error",
          content: 'Se produjo un error al cargar los distribuidores.',
          position: NF.Notification.Positions.TOP_RIGHT,
          clickClose: true,
          autoHideDelay: 8000,
        });
      });
  }

  limpiarDistribuidor() {
    this.onSearch();

    this.productores = [];
    this.filterForm.get('productor').setValue('');
  }


  getProductores() {
    this.onSearch();

    this.filterForm.get('productor').setValue('');
    this.productores = [];
    let idDistribuidor = 0;
    if (this.filterForm.value.distribuidor && this.filterForm.value.distribuidor.length) {
      idDistribuidor = this.filterForm.value.distribuidor[0].id;
    }
    this.userService.getProductoresDistribuidor(idDistribuidor)
      .subscribe(res => {
        this.productores = res;
      }, () => {

        NF.Notification.show({
          type: NF.Notification.Type.ERROR,
          title: "Error",
          content: 'Se produjo un error al cargar los productores.',
          position: NF.Notification.Positions.TOP_RIGHT,
          clickClose: true,
          autoHideDelay: 8000,
        });
      });
  }



  private createForm() {
    this.filterForm = this.filtroBuilder.group({
      empresa: [''],
      distribuidor: [''],
      productor: [''],
      insumo: ['', Validators.required]

    });
    switch (this.rolUser) {
      case 'CORPORATE':
        this.filterForm.get('empresa').setValidators([Validators.required]);
        break;
      case 'COMPANY':
        this.filterForm.get('distribuidor').setValidators([Validators.required]);
        break;
      case 'DISTRIBUTOR':
        this.filterForm.get('productor').setValidators([Validators.required]);
        break;
      default:
    }

  }

  reset() {
    this.filterForm.get('empresa').setValue('');
    this.filterForm.get('distribuidor').setValue('');
    this.filterForm.get('productor').setValue('');
    this.filterForm.get('insumo').setValue('');

    this.storageService.deleteStorageObject('filtro', 'tablero');
    this.filtro = {};
    this.ngOnInit();
  }


  goCoverageEditForm(establecimiento) {
    this.router.navigate(['/shell/coverage/form', establecimiento.id]);
  }

  showFilters() {
    this.filters = !this.filters;
  }


  getInsumosDisponibles(user) {
    if (user) {
      const insumosSeleccionados = [];
      const insumosDistintos = user.bolsas?.filter(
        (obj, index) =>
          user.bolsas.findIndex((item) => item.insumo === obj.insumo) === index
      );
      insumosDistintos?.forEach(e => {
        if (e.campaniaId === this.campaniaSeleccionada) {
          let insumo = { id: e.insumo, name: e.insumo }
          insumosSeleccionados.push(insumo);
        }

      });
      return insumosSeleccionados;
    }
    return [];
  }

  getEstadoSiniestro(estado: string): string {
    switch (estado) {
      case 'PENDIENTE':
        return 'Pendiente de Inspección';
      case 'PROCESO_INSPECCION':
        return 'En proceso de Inspección';
      case 'ASEGURADO':
        return 'Aprobado';
      case 'RECHAZADO':
        return 'Rechazado';
      case 'CERRADO':
        return 'Cerrado';
      default:
        return 'Desconocido';
    }
  }
  onDeSelectInsumo(item: any) {
    // Evita que el usuario deseleccione un elemento
    const control = this.filterForm.get('insumo');
    const currentSelection = control?.value || [];

    // Agrega nuevamente el elemento deseleccionado
    control?.setValue([...currentSelection, item]);
    console.log('No se puede deseleccionar:', item);
  }
}
